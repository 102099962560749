<template>
  <div class="navbar-container">
    <!-- 导航栏 -->
    <van-nav-bar
      class="nav-bar"
      z-index="99"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'"
    >
      <i
        slot="left"
        class="iconfont icon-jiantou"
        @click="$router.back()"
        :color="!$isLinkGame ? '#ffd400' : '#fff'"
        v-if="!this.tabbar.includes(title)"
      ></i>
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'">{{
          title
        }}</span>
      </template>
    </van-nav-bar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    title: {
      type: String,
    },
    backColor: {
      type: String,
    },
  },
  name: '',
  components: {},
  data() {
    return {
      // 如果是这些标题，则不用显示左箭头
      tabbar: [
        'thương hiệu',
        'Khuyến mãi khuyến mãi',
        'Khuyến mại',
        'Đăng nhập thành viên',
        'Mở thưởng xổ số',
        'Đăng một danh sách',
        'vé số',
        'Đăng ký',
        '',
      ],
    }
  },
  mounted() {},
  created() {},
  methods: {},
  computed: {
    ...mapState(['user']),
  },
}
</script>

<style lang="less" scoped>
.navbar-container {
  position: fixed;
  height: 100px;
  width: 100%;
  z-index: 999;
  .white {
    // /deep/ .van-nav-bar__title {
    //   color: #fff;
    // }
  }
  .nav-bar {
    height: 100%;
    text-align: center;
    line-height: 100px;
  }
  .van-nav-bar {
    font-size: 33px;
    font-family: 'PingFang-Medium';
    color: rgb(36, 36, 36);
    .iconfont {
      font-size: 50px;
    }
  }
}
</style>
