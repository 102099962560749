export function unique(arr) {
  const res = new Map()
  return arr.filter(item => !res.has(item.id) && res.set(item.id, 1))
}

/**
 * 统计数组或对象长度
 */
export function count(obj) {
  try {
    if (typeof obj === 'undefined') {
      return 0
    }
    if (typeof obj === 'number') {
      obj += ''
    }
    if (typeof obj.length === 'number') {
      return obj.length
    } else {
      let i = 0
      let key
      for (key in obj) {
        i++
      }
      return i
    }
  } catch (e) {
    return 0
  }
}


/**
 * 玩法
 * @param type
 * @returns {*}
 */
export function methodCn(type) {
  if (type === 'dxds') return 'Tài xỉu và Chẵn lẻ'
  if (type === 'dwd') return 'Vị trí bóng'
  if (type === 'bs') return 'Bose'
  if (type === 'bz') return 'báo'
  if (type === 'zh') return 'Hòa'

  if (type === 'qszs') return 'ba nhóm đầu tiên của ba'
  if (type === 'qszl') return 'ba nhóm sáu đầu tiên'
  if (type === 'hszs') return 'Ba nhóm cuối cùng của ba'
  if (type === 'hszl') return 'Ba nhóm sáu người cuối cùng'

  if (type === 'gyh') return 'Guan Ya He'
  if (type === 'lh') return 'rồng hổ'

  if (type === 'tm') return 'mã đặc biệt'
  if (type === 'zm') return 'mã cộng'
  if (type === 'tmdxds') return 'Kích thước đặc biệt duy nhất và thông thường'
  if (type === 'tmdxdsfh') return 'Thiết kế hỗn hợp Thiết kế hỗn hợp Thiết kế hỗn hợp'
  if (type === 'tmhsdxds') return 'Mã đặc biệt và số lẻ và số chẵn'
  if (type === 'tmwsdx') return 'mã đặc biệt kích thước phần định trị'

  if (type === 'rx1z1') return 'chọn một'
  if (type === 'rx2z2') return 'sự lựa chọn thứ hai của hai'
  if (type === 'rx3z3') return 'chọn ba trong ba'
  if (type === 'rx4z4') return 'chọn bốn trong bốn'
  if (type === 'rx5z5') return 'chọn năm trong số năm'
  if (type === 'rx6z5') return 'Chọn 6 trong số 5'
  if (type === 'rx7z5') return 'Bảy trong số năm lựa chọn'
  if (type === 'rx8z5') return 'Chọn 8 trong số 5'

  if (type === 'qszhix') return 'Ba cuộc bầu cử trực tiếp hàng đầu'
  if (type === 'qszux') return 'ba lựa chọn hàng đầu'
  if (type === 'hszhix') return '3 cuộc bầu cử trực tiếp gần đây nhất'
  if (type === 'hszux') return 'ba nhóm cuối cùng'

  if (type === 'qezhix') return 'Hai cuộc bầu cử trực tiếp đầu tiên'
  if (type === 'qezux') return 'Hai lựa chọn phổ biến'
  if (type === 'hezhix') return 'bầu cử trực tiếp thứ hai'
  if (type === 'hezux') return 'chọn nhóm thứ hai'

  if (type === 'zxzs') return 'Chọn một nhóm, Nhóm 3'
  if (type === 'zxzl') return 'Lựa chọn của nhóm thứ sáu'

  if (type === 'qedxds') return 'Hai kích thước đầu tiên là lẻ và chẵn.'
  if (type === 'hedxds') return 'Hai kích thước cuối cùng là lẻ và chẵn.'

  if (type === 'tx') return 'Trà Shaw'
  if (type === 'zx') return 'cung hoàng đạo'
  if (type === 'sqz') return 'San Quanzhong'
  if (type === 'eqz') return 'thứ hai đầy đủ giữa'
  if (type === 'tmsb') return 'mã màu sóng đặc biệt'

  if (type === 'hz') return 'Tổng giá trị'
  if (type === 'sth') return 'ba con số giống nhau'
  if (type === 'sbth') return 'ba số khác nhau'
  if (type === 'lhao') return 'số seri'
  if (type === 'eth') return 'hai con số giống nhau'
  if (type === 'ebth') return 'hai con số khác nhau'

  if (type === 'hz-hz-dxds') return 'Giá trị tổng là lẻ và chẵn.'
  if (type === 'hz-hz-3') return 'Tổng giá trị'
  if (type === 'hz-hz-4') return 'Tổng giá trị'
  if (type === 'hz-hz-5') return 'Tổng giá trị'
  if (type === 'hz-hz-6') return 'Tổng giá trị'
  if (type === 'hz-hz-7') return 'Tổng giá trị'
  if (type === 'hz-hz-8') return 'Tổng giá trị'
  if (type === 'hz-hz-9') return 'Tổng giá trị'
  if (type === 'hz-hz-10') return 'Tổng giá trị'
  if (type === 'hz-hz-11') return 'Tổng giá trị'
  if (type === 'hz-hz-12') return 'Tổng giá trị'
  if (type === 'hz-hz-13') return 'Tổng giá trị'
  if (type === 'hz-hz-14') return 'Tổng giá trị'
  if (type === 'hz-hz-15') return 'Tổng giá trị'
  if (type === 'hz-hz-16') return 'Tổng giá trị'
  if (type === 'hz-hz-17') return 'Tổng giá trị'
  if (type === 'hz-hz-18') return 'Tổng giá trị'
  if (type === 'sth-tx-tx') return 'Ba con số giống nhau cho cuộc tổng tuyển cử'
  if (type === 'sth-dx-dx') return 'Ba tùy chọn, cùng một số.'
  if (type === 'sbth-bz-bz') return 'ba số khác nhau'
  if (type === 'lhao-slh-slh') return 'Ba lần liên tiếp'
  if (type === 'eth-fx-fx') return 'kiểm tra hai số giống nhau'
  if (type === 'eth-dx-dx') return 'Hai lựa chọn, cùng một số.'
  if (type === 'ebth-bz-bz') return 'hai con số khác nhau'

  if (type === 'hl') return 'bóng hoang dã'
  if (type === 'hl-sb') return 'câu đố động vật'
  if (type === 'hl-dwd') return 'đố con số'

  return type
}

/**
 * 竞彩种类
 * @param type
 * @returns {*}
 */
export function typeCn(type) {
  if (type === 'cqssc') return 'Thời báo Trùng Khánh'
  if (type === 'bjsc') return 'Đua xe Bắc Kinh'
  if (type === 'xyft') return 'khí cầu may mắn'
  if (type === 'pcdd') return 'PC Egg (Bắc Kinh 28)'
  if (type === 'jnd28') return 'PC Egg (Canada 28)'
  if (type === 'xy28') return 'Egg PC (May mắn 28)'
  if (type === 'xjssc') return 'Thời báo Tân Cương'
  if (type === 'tjssc') return 'Thời báo Thiên Tân'
  if (type === 'sfssc') return 'xổ số ba chiều'
  if (type === 'txffc') return 'Tencent'
  if (type === 'sfpk10') return 'Ba điểm PK'
  if (type === 'hk6hc') return 'Hồng Kông Mark Six'
  if (type === 'js6hc') return 'Superna xổ số'
  if (type === 'jx11x5') return 'Jiangxi 11 Pick 5'
  if (type === 'sd11x5') return 'Sơn Đông 11 Chọn 5'
  if (type === 'sf11x5') return '5 trên 11'
  if (type === 'fc3d') return 'F U Màu 3D'
  if (type === 'sf3d') return 'ba chấm 3D'
  if (type === 'ffk3') return 'nhanh ba'
  if (type === 'hl') return 'bóng hoang dã'
  return type
}

/**
 * 竞彩种类
 * @param type
 * @returns {*}
 */
export function typeEn(type) {
  if (type === 'Thời báo Trùng Khánh') return 'cqssc'
  if (type === 'Đua xe Bắc Kinh') return 'bjsc'
  if (type === 'khí cầu may mắn') return 'xyft'
  if (type === 'PC Egg (Bắc Kinh 28)') return 'pcdd'
  if (type === 'PC Egg (Canada 28)') return 'jnd28'
  if (type === 'Egg PC (May mắn 28)') return 'xy28'
  if (type === 'Thời báo Tân Cương') return 'xjssc'
  if (type === 'Thời báo Thiên Tân') return 'tjssc'
  if (type === 'xổ số ba chiều') return 'sfssc'
  if (type === 'Tencent') return 'txffc'
  if (type === 'Ba điểm PK') return 'sfpk10'
  if (type === 'Hồng Kông Mark Six') return 'hk6hc'
  if (type === 'Superna xổ số') return 'js6hc'
  if (type === 'Jiangxi 11 Pick 5') return 'jx11x5'
  if (type === 'Sơn Đông 11 Chọn 5') return 'sd11x5'
  if (type === '5 trên 11') return 'sf11x5'
  if (type === 'F U Màu 3D') return 'fc3d'
  if (type === 'ba chấm 3D') return 'sf3d'
  if (type === 'nhanh ba') return 'ffk3'
  if (type === 'bóng hoang dã') return 'hl'
  return type
}

/**
 * 竞彩列表
 * @returns {*[]}
 */
export function typeList() {
  return [
    {
      type: 'cqssc',
      title: 'Thời báo Trùng Khánh'
    },
    {
      type: 'bjsc',
      title: 'Đua xe Bắc Kinh'
    },
    {
      type: 'xyft',
      title: 'khí cầu may mắn'
    },
    {
      type: 'pcdd',
      title: 'PC Egg (Bắc Kinh 28)'
    },
    {
      type: 'jnd28',
      title: 'PC Egg (Canada 28)'
    },
    {
      type: 'xy28',
      title: 'Egg PC (May mắn 28)'
    },
    {
      type: 'xjssc',
      title: 'Thời báo Tân Cương'
    },
    {
      type: 'tjssc',
      title: 'Thời báo Thiên Tân'
    },
    {
      type: 'sfssc',
      title: 'xổ số ba chiều'
    },
    {
      type: 'txffc',
      title: 'Tencent'
    },
    {
      type: 'sfpk10',
      title: 'Ba điểm PK'
    },
    {
      type: 'hk6hc',
      title: 'Hồng Kông Mark Six'
    },
    {
      type: 'js6hc',
      title: 'Superna xổ số'
    },
    {
      type: 'jx11x5',
      title: 'Jiangxi 11 Pick 5'
    },
    {
      type: 'sd11x5',
      title: 'Sơn Đông 11 Chọn 5'
    },
    {
      type: 'sf11x5',
      title: '5 trên 11'
    },
    {
      type: 'fc3d',
      title: 'F U Màu 3D'
    },
    {
      type: 'sf3d',
      title: 'ba chấm 3D'
    },
    {
      type: 'ffk3',
      title: 'nhanh ba'
    },
    {
      type: 'ffk3',
      title: 'bóng hoang dã'
    }
  ]
}

/**
 * 体育竞猜种类
 * @param type
 * @returns {*}
 */
export function sportsCn(type) {
  if (type === 'fifa') return 'Câu đố về World Cup'
  if (type === 'football') return 'câu đố bóng đá'
  if (type === 'basketball') return 'câu đố bóng rổ'
  return type
}

/**
 * 体育竞猜种类
 * @param type
 * @returns {*}
 */
export function sportsEn(type) {
  if (type === 'Câu đố về World Cup') return 'fifa'
  if (type === 'câu đố bóng đá') return 'football'
  if (type === 'câu đố bóng rổ') return 'basketball'
  return type
}

/**
 * 体育竞猜列表
 * @returns {*[]}
 */
export function sportsList() {
  return [
    {
      type: 'fifa',
      title: 'Câu đố về World Cup'
    },
    {
      type: 'football',
      title: 'câu đố bóng đá'
    },
    {
      type: 'basketball',
      title: 'câu đố bóng rổ'
    }
  ]
}

/**
 * 删除数组最后一một nơi值
 * @param array
 * @returns {Array}
 */
export function delLast(array) {
  const newArray = []
  if (typeof array === 'object' && array.length > 0) {
    this.each(array, (index, item) => {
      if (index < array.length - 1) {
        newArray.push(item)
      }
    })
  }
  return newArray
}

/**
 * 获取数组最后一một nơi值
 * @param array
 * @returns {*}
 */
export function last(array) {
  let str = false
  if (typeof array === 'object' && array.length > 0) {
    str = array[array.length - 1]
  }
  return str
}

export function isNullOrUndefined(obj) {
  return typeof obj === 'undefined' || obj === null
}

export function likeArray(obj) {
  return isNullOrUndefined(obj) ? false : typeof obj.length === 'number'
}

/**
 * 遍历数组、对象
 * @param elements
 * @param callback
 * @returns {*}
 */
export function each(elements, callback) {
  let i, key
  if (likeArray(elements)) {
    if (typeof elements.length === 'number') {
      for (i = 0; i < elements.length; i++) {
        if (callback.call(elements[i], i, elements[i]) === false) return elements
      }
    }
  } else {
    for (key in elements) {
      if (!elements.hasOwnProperty(key)) continue
      if (callback.call(elements[key], key, elements[key]) === false) return elements
    }
  }

  return elements
}

/**
 * 相当于 intval
 * @param str
 * @param fixed
 * @returns {number}
 */
export function runNum(str, fixed) {
  let _s = Number(str)
  if (_s + '' === 'NaN') {
    _s = 0
  }
  if (/^[0-9]*[1-9][0-9]*$/.test(fixed)) {
    _s = _s.toFixed(fixed)
    const rs = _s.indexOf('.')
    if (rs < 0) {
      _s += '.'
      for (let i = 0; i < fixed; i++) {
        _s += '0'
      }
    }
  }
  return _s
}

/**
 * 克隆对象
 * @param myObj
 * @returns {*}
 */
export function clone(myObj) {
  if (typeof (myObj) !== 'object') return myObj
  if (myObj === null) return myObj
  //
  if (likeArray(myObj)) {
    const [...myNewObj] = myObj
    return myNewObj
  } else {
    const { ...myNewObj } = myObj
    return myNewObj
  }
}

export function splitNum(lists, num) {
  const data = clone(lists)
  const temp = []; let i; let len
  for (i = 0, len = data.length; i < len; i += num) {
    temp.push(data.slice(i, i + num))
  }
  return temp
}

/**
 * 字符串是否右边包含
 * @param string
 * @param find
 * @returns {boolean}
 */
export function rightExists(string, find) {
  string += ''
  find += ''
  return (string.substring(string.length - find.length) === find)
}

/**
 * 字符串是否包含
 * @param string
 * @param find
 * @returns {boolean}
 */
export function strExists(string, find) {
  string += ''
  find += ''
  return (string.indexOf(find) !== -1)
}

/**
 * 数组是否包含
 * @param value
 * @param array
 * @returns {boolean}
 */
export function inArray(value, array) {
  if (likeArray(array)) {
    for (let i = 0; i < array.length; i++) {
      if (value === array[i]) {
        return true
      }
    }
  }
  return false
}

export function zeroFill(str, length, after) {
  str += ''
  if (str.length >= length) {
    return str
  }
  let _str = ''; let _ret = ''
  for (let i = 0; i < length; i++) {
    _str += '0'
  }
  if (after || typeof after === 'undefined') {
    _ret = (_str + '' + str).substr(length * -1)
  } else {
    _ret = (str + '' + _str).substr(0, length)
  }
  return _ret
}

export function formatDate(format, v) {
  if (format === '') {
    format = 'Y-m-d H:i:s'
  }
  if (typeof v === 'undefined') {
    v = new Date().getTime()
  } else if (/^(-)?\d{1,10}$/.test(v)) {
    v = v * 1000
  } else if (/^(-)?\d{1,13}$/.test(v)) {
    v = v * 1000
  } else if (/^(-)?\d{1,14}$/.test(v)) {
    v = v * 100
  } else if (/^(-)?\d{1,15}$/.test(v)) {
    v = v * 10
  } else if (/^(-)?\d{1,16}$/.test(v)) {
    v = v * 1
  } else {
    return v
  }
  const dateObj = new Date(v)
  if (parseInt(dateObj.getFullYear()) + '' === 'NaN') {
    return v
  }
  //
  format = format.replace(/Y/g, dateObj.getFullYear())
  format = format.replace(/m/g, zeroFill(dateObj.getMonth() + 1, 2))
  format = format.replace(/d/g, zeroFill(dateObj.getDate(), 2))
  format = format.replace(/H/g, zeroFill(dateObj.getHours(), 2))
  format = format.replace(/i/g, zeroFill(dateObj.getMinutes(), 2))
  format = format.replace(/s/g, zeroFill(dateObj.getSeconds(), 2))
  return format
}

export const isAppIos = () => {
  let sys = navigator.userAgent
  if (!!sys.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    return true
  } else {
    return false
  }
}
