import request from '@/utils/request.js'

// 获取lottery类型数据
export const getLotteryType = (data) => {
  return request({
    method: 'GET',
    url: 'lottery/setting',
    params: data
  })
}

// 获取当前开奖数据
export const getLotteryInfo = (data) => {
  return request({
    method: 'GET',
    url: 'lottery/get',
    params: data
  })
}

// 获取开奖数据列表
export const getLotteryList = (data) => {
  return request({
    method: 'GET',
    url: 'lottery/lists',
    params: data
  })
}

// 获取记录的信息
export const getLotteryDetail = (data) => {
  return request({
    method: 'GET',
    url: 'lottery/order',
    params: data
  })
}

// 获取开奖记录的数据
export const getBalanceRecord = (data) => {
  return request({
    method: 'GET',
    url: 'users/account/lists',
    params: data
  })
}

// 新的下注接口
export const getLotteryPost = (data) => {
  return request({
    method: 'POST',
    url: 'lottery/send',
    data
  })
}

// 新的下注接口
export const getLotteryOdds = (data) => {
  return request({
    method: 'POST',
    url: 'lottery/odds',
    data
  })
}
